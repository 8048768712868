.order .container {
    margin: 30px auto;
    display: flex;
    gap: 30px;
}

.order .box {
    border: 1px solid #ddd;
    flex: 3;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.order .user {
    flex: 3;
}

.order .box:last-child {
    flex: 1;
}

.order .box-title {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    text-transform: uppercase;
}
