.login {
    background-color: #eee;
    width: 100%;
    height: calc(100vh - 62px);
    position: relative;
}

.message {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    color: white;
    font-weight: bold;
    background-color: #ff00009d;
}

.login .card {
    background-color: #fff;
    padding: 20px;
    width: 320px;
    position: absolute;
    top: 50%;

    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.login .card .login-title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 30px;
    font-weight: bold;
}

.login .card form {
    width: 100%;
}

.login form input {
    display: block;
    width: 100%;
    border: none;
    padding: 10px;
    outline: none;
    border-bottom: 1px solid rgb(47, 42, 42);
    margin-bottom: 20px;
}

.login form input[type="checkbox"] {
    width: fit-content;
    display: inline;
}

.login .card a {
    display: inline;
}

.login form button {
    width: 80%;
    border: none;
    padding: 10px 20px;
    background-color: #f1c40f;
    outline: none;
    cursor: pointer;
    margin: 30px 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
