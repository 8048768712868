.product-page {
    padding: 30px 0;
}

.product-page .box {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 434px;
    width: 100%;
    gap: 30px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
}

.product-page .image {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-page .image img {
    max-width: 500px;
}

.product-page .info {
    width: 45%;
    height: 100%;
    padding: 30px;
    border-left: 1px solid #ccc;
}

.product-page .name {
    text-transform: capitalize;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    line-height: 1.25;
    font-size: 35px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .product-page .box {
        flex-direction: column;
    }
    .product-page .box .image {
        width: 100%;
    }

    .product-page .info {
        border: none;
        width: 100%;
    }

    .product-page .name {
        font-size: 25px;
    }
}

.product-page .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: fit-content;
    margin-bottom: 20px;
}

.product-page .rating a {
    text-decoration: none;
}

.product-page .star-icon {
    color: #e7721b;
    font-size: 22px;
}

.product-page p {
    margin-bottom: 10px;
}

.product-page .info p span {
    font-weight: bold;
    margin-right: 10px;
}

.product-page .price {
    font-size: 20px;
    font-weight: bold;
    padding: 0;
    background-color: transparent;
}

.product-page .counter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    width: fit-content;
}

.product-page .btn {
    background: var(--alt);
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.product-page .counter {
    padding: 10px;
    border: 1px solid #000;
    border-radius: 5px;
    margin-bottom: -2px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.product-page .add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    background: var(--alt);
    color: #fff;
    font-size: 0.875rem;
    border: none;
    width: 100%;
    height: 42px;
    font-weight: bold;
    transition: background-color var(--main-transition) linear;
    -webkit-transition: background-color var(--main-transition) linear;
    -moz-transition: background-color var(--main-transition) linear;
    -ms-transition: background-color var(--main-transition) linear;
    -o-transition: background-color var(--main-transition) linear;
}

.product-page .add-to-cart:hover {
    background-color: #1a4046;
}

.product-page .add-to-cart .icon {
    font-size: 20px;
}

.product-page .description {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--alt);
    gap: 20px;
    padding: 20px;
    margin-bottom: 30px;
}

.product-page .description .btns {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}

.product-page .description .btns button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: var(--main-color);
    padding-bottom: 10px;
    transition: all 0.3s ease-in-out;
}

.product-page .description .btns button.active {
    color: var(--alt);
    position: relative;
}

.product-page .description .btns button.active::before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--alt);
    z-index: -1;
}

.product-page .description .content {
    font-size: 14px;
    color: #777;
}

.product-page .description .content a {
    color: blue;
}

.product-page .tranding .products {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    gap: 20px;
}

.product-page .tranding .name {
    font-family: Nunito, Clear Sans, arial, sans-serif;
    color: var(--main-color);
    font-weight: 700;
    text-transform: none;
    font-size: 1em;
    margin-bottom: 10px;
}

.product-page .reviews {
    color: var(--main-color);
}

.product-page .reviews .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.product-page .reviews .reviews-number {
    font-weight: 400;
    text-transform: none;
    font-size: 3rem;
}

.product-page .comment {
    border-top: 1px solid #ddd;
    padding: 30px 0;
}

.product-page .comment .user-info {
    display: flex;
    gap: 10px;
}

.product-page .comment .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: aqua;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.product-page .comment .user-name {
    color: blue;
    font-weight: bold;
    margin-bottom: 5px;
}

.product-page .user-rec {
    padding: 30px;
}

.product-page .user-rec span {
    font-weight: bold;
    font-size: 14px;
}

.product-page .review {
    padding: 0 30px;
}

.product-page .review .review-title {
    font-weight: bold;
    margin-bottom: 10px;
}
