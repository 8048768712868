/*======================
=== Start Main Rules ===
======================*/

:root {
    --main-color: #4e6262;
    --alt: #33727c;
    --main-transition: 0.3s;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
}

ul {
    list-style: none;
}

.container {
    padding: 0 10px;
    margin: 0 auto;
}

/* Medium devices */
@media (min-width: 576px) {
    .container {
        width: 560px;
    }
}

/* // Medium devices */
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

/* // Large devices */
@media (min-width: 992px) {
    .container {
        width: 980px;
    }
}

/* // Extra large devices */
@media (min-width: 1200px) {
    .container {
        width: 1190px;
    }
}

.title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: var(--main-color);
    text-transform: none;
    font-size: 2.275rem;
    margin-bottom: 30px;
}

.flower {
    background: url(//cdn.planttherapy.com/images/web/global/Footer-Flowers.png)
        bottom no-repeat #4e6262;
}

/*====================
=== End Main Rules ===
====================*/
