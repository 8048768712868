/*==================
=== Start Header ===
==================*/

header {
    width: 100%;
    height: 62px;
    position: -webkit-sticky;
    position: sticky;
    font-family: Arial, Helvetica, sans-serif;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 3px 2px 10px 0px rgb(0 0 0 / 40%);
}

header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

header .logo img {
    width: 132px;
    height: 42px;
    margin-right: 20px;
}

header .navigation {
    display: flex;
    align-items: center;
}

header .navigation .icon {
    display: none;
}

header .navigation > ul {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    padding-left: 8px;
}

header .navigation ul .level-1 > span {
    display: block;
    font-size: 0.92rem;
    padding: 0 10px;
    height: 62px;
    width: 100%;
    line-height: 62px;
    text-align: center;
    cursor: pointer;
    color: var(--main-color);
    font-weight: bold;
    transition: color var(--main-transition) linear;
    -webkit-transition: color var(--main-transition) linear;
    -moz-transition: color var(--main-transition) linear;
    -ms-transition: color var(--main-transition) linear;
    -o-transition: color var(--main-transition) linear;
}

header .navigation ul .level-1 span a {
    text-decoration: none;
    color: var(--main-color);
    transition: color var(--main-transition) linear;
    -webkit-transition: color var(--main-transition) linear;
    -moz-transition: color var(--main-transition) linear;
    -ms-transition: color var(--main-transition) linear;
    -o-transition: color var(--main-transition) linear;
}

header .navigation ul .level-1:hover > span a {
    color: #fff;
}

@media (max-width: 767px) {
    header .navigation {
        flex-direction: row-reverse;
        grid-gap: 10px;
        gap: 10px;
    }

    header .navigation .icon {
        display: block;
        font-size: 25px;
        cursor: pointer;
    }
}

header .navigation ul .level-1:hover > span {
    background-color: var(--main-color);
    color: #fff;
}

header .navigation ul .level-1 > ul {
    display: none;
    z-index: 100;
    padding: 30px;
    width: 1000px;
    border: 1px solid #000;
    background-color: #fff;
    box-shadow: 6px 5px 10px 0px rgb(0 0 0 / 40%);
    position: absolute;
    top: 62px;
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
}

header .navigation ul .level-1:hover > ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 30px;
    gap: 30px;
}

header .navigation .level-2 a {
    color: var(--main-color);
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
}

header .navigation .level-3 a {
    font-weight: normal;
}

@media (max-width: 767px) {
    header .navigation ul,
    header .navigation ul .level-1 > ul {
        display: flex;
        padding: 30px;
        grid-gap: 20px;
        gap: 20px;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        text-align: center;
        background-color: #fff;
        transition: all var(--main-transition) linear;
        -webkit-transition: all var(--main-transition) linear;
        -moz-transition: all var(--main-transition) linear;
        -ms-transition: all var(--main-transition) linear;
        -o-transition: all var(--main-transition) linear;
    }

    header .navigation ul .level-1 > ul.active li {
        border: 1px solid;
        padding: 20px;
        font-weight: bold;
        width: 100%;
        cursor: pointer;
    }

    header .navigation ul.active,
    header .navigation ul .level-1 > ul.active {
        opacity: 1;
        pointer-events: all;
    }

    header .navigation ul.active {
        left: 0;
        z-index: 1000;
    }

    header .navigation ul .level-1 > ul.active {
        left: 50%;
        z-index: 1001;
    }
}

header .search {
    border: 1px solid #000;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 38px;
    max-width: 270px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

header .search input {
    border: none;
    outline: none;
    flex: 1 1;
}

@media (max-width: 767px) {
    header .search {
        display: none;
    }
}

header .search .search-icon {
    cursor: pointer;
}

header .account ul {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    z-index: 100;
}

header .account ul li {
    margin-right: 5px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

header .account ul li svg {
    font-size: 20px;
    margin-right: 5px;
}

header .account ul li a {
    text-decoration: none;
    padding: 10px 20px;
    color: #418189;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
}

header .account .cart {
    position: relative;
}

header .account .cart i {
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
    font-family: Nunito, Clear Sans, arial, sans-serif;
    color: #fff;
    font-size: 14px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

/*================
=== End Header ===
================*/

.card {
    border-radius: 10px;
    overflow: hidden;

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.card img {
    width: 100%;
}

.card .box {
    background-color: #eee;
    padding: 20px;
}

.card .info {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
    color: var(--alt);
}

.card .info span {
    display: inline-block;
    margin-left: 10px;
}

.card .content {
    margin-bottom: 20px;
    color: var(--main-color);
}

.card .content a {
    justify-content: flex-start;
}

.card .article-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.card a {
    display: flex;
    text-decoration: none;
    color: var(--main-color);
    justify-content: flex-end;
}

.banner {
    width: 100vw;
    max-height: 400px;
    padding: 50px 0;
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.banner .title {
    color: #fff;
}

.banner p {
    color: #fff;
}

.banner form {
    max-width: 90%;
    width: 500px;
    display: flex;
    border: 1px solid #ddd;
    overflow: hidden;
    padding: 20px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.banner form input {
    border: none;
    flex: 1 1;
    outline: none;
    background-color: transparent;
    color: #fff;
}

.banner form input::-webkit-input-placeholder {
    color: #eee;
}

.banner form input::placeholder {
    color: #eee;
}

.banner .icon {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 20px;
}

.categories a {
    display: block;
    padding: 0 20px;
    color: #777;
    text-decoration: none;
    transition: color var(--main-transition) linear;
    -webkit-transition: color var(--main-transition) linear;
    -moz-transition: color var(--main-transition) linear;
    -ms-transition: color var(--main-transition) linear;
    -o-transition: color var(--main-transition) linear;
}

.categories a:hover {
    color: var(--alt);
    text-decoration: underline;
}

.categories a:not(:last-child) {
    border-right: 1px solid #777;
}

/*===================
=== Start Product ===
===================*/

.product {
    display: grid;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 200px;
    grid-gap: 10px;
    gap: 10px;
    transition: all var(--main-transition) linear;
    -webkit-transition: all var(--main-transition) linear;
    -moz-transition: all var(--main-transition) linear;
    -ms-transition: all var(--main-transition) linear;
    -o-transition: all var(--main-transition) linear;
}

.product:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.product .name {
    font-family: Nunito, Clear Sans, arial, sans-serif;
    color: var(--main-color);
    font-weight: 550;
    text-transform: none;
    text-decoration: none;
    font-size: 1em;
    margin-bottom: 10px;
    text-align: center;
}

.product .rating {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.product .icon-star {
    color: #e7721b;
    font-size: 20px;
}

.product .rating .rate {
    color: var(--main-color);
    font-weight: bold;
}

.product .image {
    display: block;
    margin: 0 auto;
    width: 150px;
}

.product .price {
    padding: 7px;
    background-color: #f6f5f2;
    border-radius: 12px;
    color:#6D665F;
    font-weight: normal;
    width: 250px;
    height: 30px;
}

.product .price span {
    font-family: Arial, Helvetica, sans-serif;
    color: #565454;
    font-weight: bold;
}

.product .add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    background: #577c31;
    color: #fff;
    font-size: 0.975rem;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 20px;
    border: none;
    width: 100%;
    height: 42px;
}

.product .add-to-cart .icon {
    font-size: 22px;
}

/*=================
=== End Product ===
=================*/

/*===================
=== Start Contact ===
===================*/

.contact {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    position: relative;
}

.contact .title {
    color: #f9faf7;
}

.contact .discount {
    font-size: 1.1em;
    color: #f9faf7;
    font-weight: 400;
    margin: 15px 0;
}

.contact .offer {
    font-size: 0.8em;
    color: #f9faf7;
}

.contact form {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-around;
}

.contact .email,
.contact .phone {
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
}

.contact .email input,
.contact .phone input {
    border-radius: 25px;
    width: 270px;
    margin-right: 15px;
    border: 1px solid #c7c4bf;
    font-family: inherit;
    font-size: 1rem;
    height: 3em;
    line-height: 3;
    outline: none;
    padding: 0 0.5rem;
    margin-bottom: 20px;
}

.contact .email button,
.contact .phone button {
    padding: 0 10px;
    border-radius: 25px;
    cursor: pointer;
    height: 3em;
    line-height: 3;
    font-size: 100%;
    border: 0;
    background-color: #007bff;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
}

.contact .description {
    text-align: left;
    font-size: 0.8em;
    color: #f9faf7;
    font-weight: 500;
    margin-top: 20px;
    line-height: 18px;
}

/*=================
=== End Contact ===
=================*/

/*====================
=== Start Services ===
====================*/

.services {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 128px;
    border-bottom: 1px solid #aaa;
}

.services .box {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    height: 100%;
}

.services .box:not(:last-child) {
    border-right: 1px solid #aaa;
}

.services .box .icon {
    color: #78a206;
    font-size: 50px;
}

.services .box a {
    color: var(--main-color);
    transition: color var(--main-transition) linear;
    -webkit-transition: color var(--main-transition) linear;
    -moz-transition: color var(--main-transition) linear;
    -ms-transition: color var(--main-transition) linear;
    -o-transition: color var(--main-transition) linear;
    text-decoration: none;
}

.services .box a:hover {
    color: #78a206;
}

/*==================
=== End Services ===
==================*/

/*==================
=== Start Footer ===
==================*/

footer {
    padding-top: 30px;
    padding-bottom: 30px;
}

footer .container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
}

footer .left {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
}

@media (max-width: 767px) {
    footer .left {
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}

footer .left .box {
    min-width: 25%;
}

footer p {
    color: var(--main-color);
    margin-top: 4px;
}

footer .title {
    font-size: 1.2em;
    font-weight: 700;
    color: var(--main-color);
}

footer .right {
    text-align: center;
    margin-bottom: 20px;
    margin-right: 10px;
}

footer .right img {
    max-width: 300px;
    margin-bottom: 20px;
}

/*================
=== End Footer ===
================*/

/*================
=== Start Hero ===
================*/

.hero {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.hero .slider {
    width: 100vw;
    display: flex;
    transition: all var(--main-transition) linear;
    -webkit-transition: all var(--main-transition) linear;
    -moz-transition: all var(--main-transition) linear;
    -ms-transition: all var(--main-transition) linear;
    -o-transition: all var(--main-transition) linear;
}

.hero .slider img {
    width: 100vw;
}

.hero .controle .btns {
    width: 100%;
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.hero .controle .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid #000;
    font-size: 20px;
    background-color: transparent;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.hero .controle .dots {
    position: absolute;
    left: 50%;
    bottom: 130px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.hero .controle .dot {
    border: 2px solid #000;
    background-color: transparent;
    border-radius: 50%;
    width: 15px;
    cursor: pointer;
    height: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.hero .controle .dot.active {
    background-color: #000;
}

.hero .buy {
    min-height: 100px;
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    position: relative;
    z-index: 30;
}

.hero .buy p {
    color: #fff;
    font-size: 1.3rem;
    display: block;
    margin: 25px 0;
}

.hero .buy button {
    margin-bottom: 0;
    margin-left: 20px;
    color: var(--main-color);
    border: 1px solid var(--alt);
    min-width: 200px;
    height: 40px;
    text-transform: uppercase;
    border-radius: 20px;
    line-height: 40px;
    padding: 0 20px;
    font-weight: 700;
    display: inline-block;
    position: relative;
    background: #e1e1e1;
    transition-duration: var(--main-transition);
    cursor: pointer;
}

.hero .buy button:hover {
    background-color: var(--alt);
}

/*================
=== End Hero ===
================*/

.products {
    padding: 30px 0;
    text-align: center;
    position: relative;
    z-index: 9;
    background-color: #fff;
}

.products .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
}

/*===================
=== Start Product ===
===================*/

.shop .product {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
    border-bottom: 1px solid #ddd;
    padding: 20px;
}

@media (max-width: 767px) {
    .shop .product {
        flex-direction: column;
        text-align: center;
    }
}

.shop .product img {
    width: 150px;
}

.shop .product .name {
    font-family: Nunito, Clear Sans, arial, sans-serif;
    color: var(--main-color);
    font-weight: 700;
    text-transform: none;
    font-size: 1em;
    margin-bottom: 10px;
    text-decoration: none;
}

.shop .product .price {
    padding: 0;
    background-color: transparent;
    margin: 20px 0;
}

.shop .product .price span {
    font-weight: bold;
}

.shop .product .btn {
    padding: 10px 15px;
    border: none;
    margin-right: 5px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 20px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.shop .product .btn.view {
    background-color: #8e805d;
}

.shop .product .btn.list {
    background-color: #7c7c76;
}

.shop .product .share {
    color: var(--main-color);
}

.shop .product .share span,
.shop .product .share .icon {
    margin-right: 10px;
}

.shop .product .share a {
    text-decoration: none;
    font-weight: 14px;
    color: var(--main-color);
}

/*=================
=== End Product ===
=================*/

.quick-view {
    position: fixed;
    top: 62px;
    z-index: 99999;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    animation: open var(--main-transition) linear;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-animation: open var(--main-transition) linear;
}

.quick-view .product {
    background-color: #fff;
    min-width: 90%;
    max-width: 1000px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 50px;
    align-items: center;
    padding: 50px !important;
    position: relative;
}

.quick-view .product:hover {
    transform: scale(1);
    box-shadow: none;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}

.quick-view .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #fff;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.quick-view .product .product-image {
    border-right: 1px solid #ddd;
    padding-right: 30px;
}

.quick-view .product .product-image img {
    width: 200px;
}

.quick-view .product .product-info {
    margin-left: 30px;
}

.quick-view .product .product-name a {
    display: block;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: var(--main-color);
}

.quick-view .product .product-info span {
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
    margin-bottom: 10px;
}

.quick-view .product-size {
    margin-bottom: 10px;
}

.quick-view .product .product-size span {
    display: block;
}

.quick-view .product-size .btns {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.quick-view .product-size .btns .size {
    padding: 5px 10px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    border: none;
    background-color: var(--main-color);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.quick-view .product-size .btns .size.active {
    background-color: var(--alt);
}

.quick-view .product-price {
    font-weight: bold;
    margin: 20px 0;
}

.quick-view .btns .cart {
    background-color: var(--main-color);
}

.quick-view .product-counter {
    display: flex;
    align-items: center;
    margin: 20px 0;
    grid-gap: 20px;
    gap: 20px;
}

.quick-view .product-counter button {
    background-color: var(--alt);
    border: none;
    padding: 10px 8px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
}

.quick-view .product-share {
    color: var(--main-color);
}

.quick-view .product-share .icon {
    margin-right: 10px;
    cursor: pointer;
}

.quick-view .product-share a {
    color: var(--main-color);
    text-decoration: none;
}

/* ANIMATION */

@-webkit-keyframes open {
    0% {
        margin: -50px 0;
        opacity: 0;
    }
    100% {
        margin: 0;
        opacity: 1;
    }
}

@keyframes open {
    0% {
        margin: -50px 0;
        opacity: 0;
    }
    100% {
        margin: 0;
        opacity: 1;
    }
}

.shop .shop-banner {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
        url("https://cdn11.bigcommerce.com/s-8466dwhhql/images/stencil/2048x2048/f/mrh_essential-oils_2021-01-02__77204-3_category.original.jpg");
    background-position: center;
    background-size: cover;
}

.shop .product:nth-child(even) {
    border-left: 1px solid #ddd;
}

.shop .shop-banner .content {
    text-align: center;
    color: #fff;
    font-weight: bold;
}

.shop .shop-banner .title {
    color: #fff;
    font-size: 3rem;
    text-transform: uppercase;
}

.shop .container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
}

@media (max-width: 767px) {
    .shop .container {
        grid-template-columns: repeat(1, 100%);
    }
    .shop .product {
        border-left: none;
    }
}

.product-page {
    padding: 30px 0;
}

.product-page .box {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 434px;
    width: 100%;
    grid-gap: 30px;
    gap: 30px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
}

.product-page .image {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-page .image img {
    max-width: 500px;
}

.product-page .info {
    width: 45%;
    height: 100%;
    padding: 30px;
    border-left: 1px solid #ccc;
}

.product-page .name {
    text-transform: capitalize;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    line-height: 1.25;
    font-size: 35px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .product-page .box {
        flex-direction: column;
    }
    .product-page .box .image {
        width: 100%;
    }

    .product-page .info {
        border: none;
        width: 100%;
    }

    .product-page .name {
        font-size: 25px;
    }
}

.product-page .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 20px;
}

.product-page .rating a {
    text-decoration: none;
}

.product-page .star-icon {
    color: #e7721b;
    font-size: 22px;
}

.product-page p {
    margin-bottom: 10px;
}

.product-page .info p span {
    font-weight: bold;
    margin-right: 10px;
}

.product-page .price {
    font-size: 20px;
    font-weight: bold;
    padding: 0;
    background-color: transparent;
}

.product-page .counter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.product-page .btn {
    background: var(--alt);
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.product-page .counter {
    padding: 10px;
    border: 1px solid #000;
    border-radius: 5px;
    margin-bottom: -2px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.product-page .add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    cursor: pointer;
    background: var(--alt);
    color: #fff;
    font-size: 0.875rem;
    border: none;
    width: 100%;
    height: 42px;
    font-weight: bold;
    transition: background-color var(--main-transition) linear;
    -webkit-transition: background-color var(--main-transition) linear;
    -moz-transition: background-color var(--main-transition) linear;
    -ms-transition: background-color var(--main-transition) linear;
    -o-transition: background-color var(--main-transition) linear;
}

.product-page .add-to-cart:hover {
    background-color: #1a4046;
}

.product-page .add-to-cart .icon {
    font-size: 20px;
}

.product-page .description {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--alt);
    grid-gap: 20px;
    gap: 20px;
    padding: 20px;
    margin-bottom: 30px;
}

.product-page .description .btns {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
}

.product-page .description .btns button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: var(--main-color);
    padding-bottom: 10px;
    transition: all 0.3s ease-in-out;
}

.product-page .description .btns button.active {
    color: var(--alt);
    position: relative;
}

.product-page .description .btns button.active::before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--alt);
    z-index: -1;
}

.product-page .description .content {
    font-size: 14px;
    color: #777;
}

.product-page .description .content a {
    color: blue;
}

.product-page .tranding .products {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    grid-gap: 20px;
    gap: 20px;
}

.product-page .tranding .name {
    font-family: Nunito, Clear Sans, arial, sans-serif;
    color: var(--main-color);
    font-weight: 700;
    text-transform: none;
    font-size: 1em;
    margin-bottom: 10px;
}

.product-page .reviews {
    color: var(--main-color);
}

.product-page .reviews .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.product-page .reviews .reviews-number {
    font-weight: 400;
    text-transform: none;
    font-size: 3rem;
}

.product-page .comment {
    border-top: 1px solid #ddd;
    padding: 30px 0;
}

.product-page .comment .user-info {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.product-page .comment .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: aqua;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.product-page .comment .user-name {
    color: blue;
    font-weight: bold;
    margin-bottom: 5px;
}

.product-page .user-rec {
    padding: 30px;
}

.product-page .user-rec span {
    font-weight: bold;
    font-size: 14px;
}

.product-page .review {
    padding: 0 30px;
}

.product-page .review .review-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.blog .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
    margin: 30px;
}

.post {
    margin: 30px 0;
}

.post .container {
    display: flex;
    width: 80%;
    flex-direction: column;
}

.post .post-thumbnail,
.post .post-content .post-image {
    width: 100%;
    border-radius: 20px;
    margin: 0.8rem 0;
    overflow: hidden;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.post .post-thumbnail img,
.post .post-content div img {
    width: 100%;
}

.post .post-title {
    font-size: 2rem;
    line-height: 1.5;
    margin: 1.3rem 0;
    color: var(--main-color);
}

.post .post-content {
    color: var(--main-color);
}

.post .post-content p {
    margin: 20px 0;
    font-weight: 500;
    line-height: 25px;
}

.post .post-content h2 {
    font-size: 1.875rem;
    line-height: 1.6;
    font-weight: bold;
}

.post .share-post {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 20px 0;
    margin-top: 10px;
    border: 1px solid transparent;
    border-top-color: var(--main-color);
    border-bottom-color: var(--main-color);
}

.post h3 {
    font-size: 0.8rem;
    margin: 20px 0;
}

.post .share-post .btns button {
    margin-right: 20px;
    padding: 5px 10px;
    cursor: pointer;
    color: var(--main-color);
}

.post .share-post .btns button span {
    margin-left: 5px;
}

.post .posts {
    width: 100%;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.leave-comment {
    margin: 30px 0;
}

.leave-comment form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leave-comment textarea,
.leave-comment input {
    width: 70%;
    margin-bottom: 10px;
    padding: 10px;
    outline: none;
}

.leave-comment textarea {
    height: 200px;
    resize: none;
}

.leave-comment input[type="submit"] {
    margin-bottom: 0;
    margin-left: 20px;
    color: var(--main-color);
    border: 1px solid var(--alt);
    width: 200px;
    height: 40px;
    text-transform: uppercase;
    border-radius: 20px;
    line-height: 40px;
    padding: 0 20px;
    font-weight: 700;
    display: inline-block;
    position: relative;
    background: #e1e1e1;
    transition-duration: var(--main-transition);
    cursor: pointer;
}

.leave-comment input[type="submit"]:hover {
    color: #fff;
    background-color: var(--alt);
}

.comments {
    margin: 30px 0;
    color: var(--main-color);
}

.comments .comments-title {
    font-size: 1.7rem;
    line-height: 1.5;
    margin: 1.3rem 0;
}

.comments .comment:not(:last-child) {
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
}

.comments .user-name {
    font-size: 1.1rem;
    font-style: italic;
}

.comments .date {
    font-size: 0.8rem;
    margin-top: -10px;
}

.comments .comment-content {
    margin: 20px 0;
    font-weight: 500;
    line-height: 25px;
    padding-left: 30px;
}

.cart {
    padding: 30px 0;
}

.cart .cart-title {
    text-align: center;
    color: var(--main-color);
    padding: 10px 0;
    display: flex;
    align-items: center;
    margin: 30px auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
}

.cart .cart-title svg {
    font-family: Helvetica, sans-serif;
    font-size: 35px;
    margin-right: 10px;
}

.cart .cart-body {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 40px;
    gap: 40px;
}

.cart .cart-items {
    flex: 3 1;
    border: 1px solid #ddd;
    border-radius: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-border-radius: 3px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.cart-items-title {
    padding: 10px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.cart .cart-item {
    width: 100%;
    padding: 10px;
}

.cart .box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
}

.cart .cart-item .product-image img {
    width: 80px;
}

.cart .cart-item .product-info {
    flex: 1 1;
}

.cart .cart-item .product-info .product-name {
    margin-bottom: 10px;
}

.cart .cart-item .product-info .product-origine span {
    color: #777;
}

.cart .cart-item .product-price {
    font-weight: bold;
    font-size: 20px;
}

.cart .cart-item .delete {
    width: 40px;
    height: 30px;
    background-color: transparent;
    border: none;
    color: #000000;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;

}
.cart .cart-item .delete:hover{
    color: #d11027;
    border: none;
}

.cart .cart-item .quantity {
    display: flex;
}

.cart .cart-item .quantity .decrement,
.cart .cart-item .quantity .num,
.cart .cart-item .quantity .increment {
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.cart .cart-item .quantity .decrement,
.cart .cart-item .quantity .increment {
    background-color: var(--alt);
    color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.cart .cart-total {
    flex: 1 1;
    border: 1px solid #ddd;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.cart .cart-total .cart-total-title {
    padding: 10px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.cart .cart-total .total {
    padding: 10px;
}

.cart .cart-total .total div {
    margin-bottom: 10px;
}

.cart .cart-total .checkout {
    display: block;
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #fff;
    font-weight: bold;
    background-color: #f1c40f;
    cursor: pointer;
    border: none;
    transition: background-color var(--main-transition) linear;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    -webkit-transition: background-color var(--main-transition) linear;
    -moz-transition: background-color var(--main-transition) linear;
    -ms-transition: background-color var(--main-transition) linear;
    -o-transition: background-color var(--main-transition) linear;
}

.cart .cart-total .checkout:hover {
    background-color: #bc990c;
}

.cart .recommended {
    margin-top: 30px;
}

.cart .recommended .products-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.no-result {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-result p {
    margin-top: -60px;
    margin-bottom: 50px;
    font-size: 60px;
    font-weight: bold;
}

.no-result a {
    text-decoration: none;
    padding: 10px 20px;
    background-color: var(--alt);
    color: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.login {
    background-color: #eee;
    width: 100%;
    height: calc(100vh - 62px);
    position: relative;
}

.message {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    color: white;
    font-weight: bold;
    background-color: #ff00009d;
}

.login .card {
    background-color: #fff;
    padding: 20px;
    width: 320px;
    position: absolute;
    top: 50%;

    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.login .card .login-title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 30px;
    font-weight: bold;
}

.login .card form {
    width: 100%;
}

.login form input {
    display: block;
    width: 100%;
    border: none;
    padding: 10px;
    outline: none;
    border-bottom: 1px solid rgb(47, 42, 42);
    margin-bottom: 20px;
}

.login form input[type="checkbox"] {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline;
}

.login .card a {
    display: inline;
}

.login form button {
    width: 80%;
    border: none;
    padding: 10px 20px;
    background-color: #f1c40f;
    outline: none;
    cursor: pointer;
    margin: 30px 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.register {
    background-color: #eee;
    width: 100%;
    height: calc(100vh - 62px);
    position: relative;
}

.message {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    color: white;
    font-weight: bold;
    background-color: #ff00009d;
}

.register .card {
    background-color: #fff;
    padding: 20px;
    width: 320px;
    position: absolute;
    top: 50%;

    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.register .card .register-title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 30px;
    font-weight: bold;
}

.register .card form {
    width: 100%;
}

.register form input {
    display: block;
    width: 100%;
    border: none;
    padding: 10px;
    outline: none;
    border-bottom: 1px solid rgb(47, 42, 42);
    margin-bottom: 20px;
}

.register form input[type="checkbox"] {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline;
}

.register form button {
    width: 80%;
    border: none;
    padding: 10px 20px;
    background-color: #f1c40f;
    outline: none;
    cursor: pointer;
    margin: 30px 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.empty {
    width: 100%;
    padding: 10px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    background-color: #ff00009d;
    margin-bottom: 10px;
}

.order-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    grid-gap: 20px;
    gap: 20px;
    border: 1px solid #ddd;
    margin: 10px;
}

@media (max-width: 767px) {
    .order {
        flex-direction: column;
    }
}

.order-card .center {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.order-card .center .status {
    background-color: #f1c40f66;
    padding: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.order-card .right button {
    padding: 10px 20px;
    background-color: #f1c40f;
    border: 0;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.profile .profile-container {
    margin: 50px auto;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.profile .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.profile .profile-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.profile .logout {
    padding: 10px 20px;
    background-color: var(--alt);
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color var(--main-transition) linear;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transition: background-color var(--main-transition) linear;
    -moz-transition: background-color var(--main-transition) linear;
    -ms-transition: background-color var(--main-transition) linear;
    -o-transition: background-color var(--main-transition) linear;
}

.profile .logout:hover {
    background-color: #285b63;
}

.profile .box {
    padding: 10px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
}

.profile .user-info,
.profile .user-adresses {
    flex: 1 1;
    border: 1px solid #ddd;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.profile .user-info-header,
.profile .user-adresses-header,
.profile .orders .oders-title {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    color: #333;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.profile .user-info-header svg,
.profile .user-adresses-header svg {
    font-size: 18px;
    cursor: pointer;
}

.profile .info,
.profile .adresses {
    padding: 10px;
}

.profile .user-info .change {
    background-color: var(--alt);
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: bold;
    margin: 0 0 10px 10px;
    cursor: pointer;
    transition: background-color var(--main-transition) linear;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transition: background-color var(--main-transition) linear;
    -moz-transition: background-color var(--main-transition) linear;
    -ms-transition: background-color var(--main-transition) linear;
    -o-transition: background-color var(--main-transition) linear;
}

.profile .user-info .change:hover {
    background-color: #42a4b3;
}

.profile .orders {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.profile .products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
}

.box .cart-items {
    border-bottom: 1px solid #ddd;
}

.box .cart-item {
    display: flex;
    padding: 10px;
}

.box .cart-item .image img {
    width: 80px;
}

.box .cart-item .info p {
    margin-bottom: 5px;
    font-size: 14px;
}

.box .cart-item .info p:nth-child(2) {
    color: #999;
    font-weight: bold;
}

.box .total {
    padding: 10px;
}

.box .btn {
    width: 80%;
    display: block;
    margin: 0 auto 10px;
    padding: 10px;
    cursor: pointer;
    border: none;
    background-color: var(--alt);
    color: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.box .user-info {
    padding: 10px;
}

.box .user-info input {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 10px;
    border: 1px solid transparent;
    border-bottom-color: #000;
    outline: none;
    display: block;
}

.box .user-info button {
    padding: 10px 30px;
    background-color: var(--alt);
    color: #fff;
    font-weight: bold;
    border: none;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color var(--main-transition) linear;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transition: background-color var(--main-transition) linear;
    -moz-transition: background-color var(--main-transition) linear;
    -ms-transition: background-color var(--main-transition) linear;
    -o-transition: background-color var(--main-transition) linear;
}

.box .user-info button:hover {
    background-color: #1a4046;
}

.order .container {
    margin: 30px auto;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
}

.order .box {
    border: 1px solid #ddd;
    flex: 3 1;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.order .user {
    flex: 3 1;
}

.order .box:last-child {
    flex: 1 1;
}

.order .box-title {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    text-transform: uppercase;
}

/*======================
=== Start Main Rules ===
======================*/

:root {
    --main-color: #4e6262;
    --alt: #33727c;
    --main-transition: 0.3s;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
}

ul {
    list-style: none;
}

.container {
    padding: 0 10px;
    margin: 0 auto;
}

/* Medium devices */
@media (min-width: 576px) {
    .container {
        width: 560px;
    }
}

/* // Medium devices */
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

/* // Large devices */
@media (min-width: 992px) {
    .container {
        width: 980px;
    }
}

/* // Extra large devices */
@media (min-width: 1200px) {
    .container {
        width: 1190px;
    }
}

.title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #4e6262;
    color: var(--main-color);
    text-transform: none;
    font-size: 2.275rem;
    margin-bottom: 30px;
}

.flower {
    background: url(//cdn.planttherapy.com/images/web/global/Footer-Flowers.png)
        bottom no-repeat #4e6262;
}

/*====================
=== End Main Rules ===
====================*/

