.box .cart-items {
    border-bottom: 1px solid #ddd;
}

.box .cart-item {
    display: flex;
    padding: 10px;
}

.box .cart-item .image img {
    width: 80px;
}

.box .cart-item .info p {
    margin-bottom: 5px;
    font-size: 14px;
}

.box .cart-item .info p:nth-child(2) {
    color: #999;
    font-weight: bold;
}

.box .total {
    padding: 10px;
}

.box .btn {
    width: 80%;
    display: block;
    margin: 0 auto 10px;
    padding: 10px;
    cursor: pointer;
    border: none;
    background-color: var(--alt);
    color: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
