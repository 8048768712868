.quick-view {
    position: fixed;
    top: 62px;
    z-index: 99999;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    animation: open var(--main-transition) linear;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-animation: open var(--main-transition) linear;
}

.quick-view .product {
    background-color: #fff;
    min-width: 90%;
    max-width: 1000px;
    height: fit-content;
    margin-top: 50px;
    align-items: center;
    padding: 50px !important;
    position: relative;
}

.quick-view .product:hover {
    transform: scale(1);
    box-shadow: none;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}

.quick-view .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #fff;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.quick-view .product .product-image {
    border-right: 1px solid #ddd;
    padding-right: 30px;
}

.quick-view .product .product-image img {
    width: 200px;
}

.quick-view .product .product-info {
    margin-left: 30px;
}

.quick-view .product .product-name a {
    display: block;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: var(--main-color);
}

.quick-view .product .product-info span {
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
    margin-bottom: 10px;
}

.quick-view .product-size {
    margin-bottom: 10px;
}

.quick-view .product .product-size span {
    display: block;
}

.quick-view .product-size .btns {
    display: flex;
    gap: 10px;
}

.quick-view .product-size .btns .size {
    padding: 5px 10px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    border: none;
    background-color: var(--main-color);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.quick-view .product-size .btns .size.active {
    background-color: var(--alt);
}

.quick-view .product-price {
    font-weight: bold;
    margin: 20px 0;
}

.quick-view .btns .cart {
    background-color: var(--main-color);
}

.quick-view .product-counter {
    display: flex;
    align-items: center;
    margin: 20px 0;
    gap: 20px;
}

.quick-view .product-counter button {
    background-color: var(--alt);
    border: none;
    padding: 10px 8px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
}

.quick-view .product-share {
    color: var(--main-color);
}

.quick-view .product-share .icon {
    margin-right: 10px;
    cursor: pointer;
}

.quick-view .product-share a {
    color: var(--main-color);
    text-decoration: none;
}

/* ANIMATION */

@keyframes open {
    0% {
        margin: -50px 0;
        opacity: 0;
    }
    100% {
        margin: 0;
        opacity: 1;
    }
}
