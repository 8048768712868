.profile .profile-container {
    margin: 50px auto;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.profile .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.profile .profile-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.profile .logout {
    padding: 10px 20px;
    background-color: var(--alt);
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color var(--main-transition) linear;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transition: background-color var(--main-transition) linear;
    -moz-transition: background-color var(--main-transition) linear;
    -ms-transition: background-color var(--main-transition) linear;
    -o-transition: background-color var(--main-transition) linear;
}

.profile .logout:hover {
    background-color: #285b63;
}

.profile .box {
    padding: 10px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.profile .user-info,
.profile .user-adresses {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.profile .user-info-header,
.profile .user-adresses-header,
.profile .orders .oders-title {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    color: #333;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.profile .user-info-header svg,
.profile .user-adresses-header svg {
    font-size: 18px;
    cursor: pointer;
}

.profile .info,
.profile .adresses {
    padding: 10px;
}

.profile .user-info .change {
    background-color: var(--alt);
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: bold;
    margin: 0 0 10px 10px;
    cursor: pointer;
    transition: background-color var(--main-transition) linear;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transition: background-color var(--main-transition) linear;
    -moz-transition: background-color var(--main-transition) linear;
    -ms-transition: background-color var(--main-transition) linear;
    -o-transition: background-color var(--main-transition) linear;
}

.profile .user-info .change:hover {
    background-color: #42a4b3;
}

.profile .orders {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.profile .products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
}
