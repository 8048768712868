/*==================
=== Start Header ===
==================*/

header {
    width: 100%;
    height: 62px;
    position: sticky;
    font-family: Arial, Helvetica, sans-serif;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 3px 2px 10px 0px rgb(0 0 0 / 40%);
}

header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

header .logo img {
    width: 132px;
    height: 42px;
    margin-right: 20px;
}

header .navigation {
    display: flex;
    align-items: center;
}

header .navigation .icon {
    display: none;
}

header .navigation > ul {
    display: flex;
    gap: 8px;
    padding-left: 8px;
}

header .navigation ul .level-1 > span {
    display: block;
    font-size: 0.92rem;
    padding: 0 10px;
    height: 62px;
    width: 100%;
    line-height: 62px;
    text-align: center;
    cursor: pointer;
    color: var(--main-color);
    font-weight: bold;
    transition: color var(--main-transition) linear;
    -webkit-transition: color var(--main-transition) linear;
    -moz-transition: color var(--main-transition) linear;
    -ms-transition: color var(--main-transition) linear;
    -o-transition: color var(--main-transition) linear;
}

header .navigation ul .level-1 span a {
    text-decoration: none;
    color: var(--main-color);
    transition: color var(--main-transition) linear;
    -webkit-transition: color var(--main-transition) linear;
    -moz-transition: color var(--main-transition) linear;
    -ms-transition: color var(--main-transition) linear;
    -o-transition: color var(--main-transition) linear;
}

header .navigation ul .level-1:hover > span a {
    color: #fff;
}

@media (max-width: 767px) {
    header .navigation {
        flex-direction: row-reverse;
        gap: 10px;
    }

    header .navigation .icon {
        display: block;
        font-size: 25px;
        cursor: pointer;
    }
}

header .navigation ul .level-1:hover > span {
    background-color: var(--main-color);
    color: #fff;
}

header .navigation ul .level-1 > ul {
    display: none;
    z-index: 100;
    padding: 30px;
    width: 1000px;
    border: 1px solid #000;
    background-color: #fff;
    box-shadow: 6px 5px 10px 0px rgb(0 0 0 / 40%);
    position: absolute;
    top: 62px;
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
}

header .navigation ul .level-1:hover > ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    gap: 30px;
}

header .navigation .level-2 a {
    color: var(--main-color);
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
}

header .navigation .level-3 a {
    font-weight: normal;
}

@media (max-width: 767px) {
    header .navigation ul,
    header .navigation ul .level-1 > ul {
        display: flex;
        padding: 30px;
        gap: 20px;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        text-align: center;
        background-color: #fff;
        transition: all var(--main-transition) linear;
        -webkit-transition: all var(--main-transition) linear;
        -moz-transition: all var(--main-transition) linear;
        -ms-transition: all var(--main-transition) linear;
        -o-transition: all var(--main-transition) linear;
    }

    header .navigation ul .level-1 > ul.active li {
        border: 1px solid;
        padding: 20px;
        font-weight: bold;
        width: 100%;
        cursor: pointer;
    }

    header .navigation ul.active,
    header .navigation ul .level-1 > ul.active {
        opacity: 1;
        pointer-events: all;
    }

    header .navigation ul.active {
        left: 0;
        z-index: 1000;
    }

    header .navigation ul .level-1 > ul.active {
        left: 50%;
        z-index: 1001;
    }
}

header .search {
    border: 1px solid #000;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 38px;
    max-width: 270px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

header .search input {
    border: none;
    outline: none;
    flex: 1;
}

@media (max-width: 767px) {
    header .search {
        display: none;
    }
}

header .search .search-icon {
    cursor: pointer;
}

header .account ul {
    display: flex;
    gap: 10px;
    z-index: 100;
}

header .account ul li {
    margin-right: 5px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

header .account ul li svg {
    font-size: 20px;
    margin-right: 5px;
}

header .account ul li a {
    text-decoration: none;
    padding: 10px 20px;
    color: #418189;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
}

header .account .cart {
    position: relative;
}

header .account .cart i {
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
    font-family: Nunito, Clear Sans, arial, sans-serif;
    color: #fff;
    font-size: 14px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

/*================
=== End Header ===
================*/
