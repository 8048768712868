.box .user-info {
    padding: 10px;
}

.box .user-info input {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 10px;
    border: 1px solid transparent;
    border-bottom-color: #000;
    outline: none;
    display: block;
}

.box .user-info button {
    padding: 10px 30px;
    background-color: var(--alt);
    color: #fff;
    font-weight: bold;
    border: none;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color var(--main-transition) linear;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transition: background-color var(--main-transition) linear;
    -moz-transition: background-color var(--main-transition) linear;
    -ms-transition: background-color var(--main-transition) linear;
    -o-transition: background-color var(--main-transition) linear;
}

.box .user-info button:hover {
    background-color: #1a4046;
}
