.products {
    padding: 30px 0;
    text-align: center;
    position: relative;
    z-index: 9;
    background-color: #fff;
}

.products .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    justify-content: center;
    gap: 30px;
}
