/*==================
=== Start Footer ===
==================*/

footer {
    padding-top: 30px;
    padding-bottom: 30px;
}

footer .container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
}

footer .left {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
}

@media (max-width: 767px) {
    footer .left {
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}

footer .left .box {
    min-width: 25%;
}

footer p {
    color: var(--main-color);
    margin-top: 4px;
}

footer .title {
    font-size: 1.2em;
    font-weight: 700;
    color: var(--main-color);
}

footer .right {
    text-align: center;
    margin-bottom: 20px;
    margin-right: 10px;
}

footer .right img {
    max-width: 300px;
    margin-bottom: 20px;
}

/*================
=== End Footer ===
================*/
