/*===================
=== Start Product ===
===================*/

.product {
    display: grid;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 200px;
    gap: 10px;
    transition: all var(--main-transition) linear;
    -webkit-transition: all var(--main-transition) linear;
    -moz-transition: all var(--main-transition) linear;
    -ms-transition: all var(--main-transition) linear;
    -o-transition: all var(--main-transition) linear;
}

.product:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.product .name {
    font-family: Nunito, Clear Sans, arial, sans-serif;
    color: var(--main-color);
    font-weight: 550;
    text-transform: none;
    text-decoration: none;
    font-size: 1em;
    margin-bottom: 10px;
    text-align: center;
}

.product .rating {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.product .icon-star {
    color: #e7721b;
    font-size: 20px;
}

.product .rating .rate {
    color: var(--main-color);
    font-weight: bold;
}

.product .image {
    display: block;
    margin: 0 auto;
    width: 150px;
}

.product .price {
    padding: 7px;
    background-color: #f6f5f2;
    border-radius: 12px;
    color:#6D665F;
    font-weight: normal;
    width: 250px;
    height: 30px;
}

.product .price span {
    font-family: Arial, Helvetica, sans-serif;
    color: #565454;
    font-weight: bold;
}

.product .add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background: #577c31;
    color: #fff;
    font-size: 0.975rem;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 20px;
    border: none;
    width: 100%;
    height: 42px;
}

.product .add-to-cart .icon {
    font-size: 22px;
}

/*=================
=== End Product ===
=================*/
