.cart {
    padding: 30px 0;
}

.cart .cart-title {
    text-align: center;
    color: var(--main-color);
    padding: 10px 0;
    display: flex;
    align-items: center;
    margin: 30px auto;
    width: fit-content;
    position: relative;
}

.cart .cart-title svg {
    font-family: Helvetica, sans-serif;
    font-size: 35px;
    margin-right: 10px;
}

.cart .cart-body {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.cart .cart-items {
    flex: 3;
    border: 1px solid #ddd;
    border-radius: 10px;
    height: fit-content;
    -webkit-border-radius: 3px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.cart-items-title {
    padding: 10px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.cart .cart-item {
    width: 100%;
    padding: 10px;
}

.cart .box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.cart .cart-item .product-image img {
    width: 80px;
}

.cart .cart-item .product-info {
    flex: 1;
}

.cart .cart-item .product-info .product-name {
    margin-bottom: 10px;
}

.cart .cart-item .product-info .product-origine span {
    color: #777;
}

.cart .cart-item .product-price {
    font-weight: bold;
    font-size: 20px;
}

.cart .cart-item .delete {
    width: 40px;
    height: 30px;
    background-color: transparent;
    border: none;
    color: #000000;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;

}
.cart .cart-item .delete:hover{
    color: #d11027;
    border: none;
}

.cart .cart-item .quantity {
    display: flex;
}

.cart .cart-item .quantity .decrement,
.cart .cart-item .quantity .num,
.cart .cart-item .quantity .increment {
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.cart .cart-item .quantity .decrement,
.cart .cart-item .quantity .increment {
    background-color: var(--alt);
    color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.cart .cart-total {
    flex: 1;
    border: 1px solid #ddd;
    height: fit-content;
    border-radius: 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.cart .cart-total .cart-total-title {
    padding: 10px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.cart .cart-total .total {
    padding: 10px;
}

.cart .cart-total .total div {
    margin-bottom: 10px;
}

.cart .cart-total .checkout {
    display: block;
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #fff;
    font-weight: bold;
    background-color: #f1c40f;
    cursor: pointer;
    border: none;
    transition: background-color var(--main-transition) linear;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    -webkit-transition: background-color var(--main-transition) linear;
    -moz-transition: background-color var(--main-transition) linear;
    -ms-transition: background-color var(--main-transition) linear;
    -o-transition: background-color var(--main-transition) linear;
}

.cart .cart-total .checkout:hover {
    background-color: #bc990c;
}

.cart .recommended {
    margin-top: 30px;
}

.cart .recommended .products-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.no-result {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-result p {
    margin-top: -60px;
    margin-bottom: 50px;
    font-size: 60px;
    font-weight: bold;
}

.no-result a {
    text-decoration: none;
    padding: 10px 20px;
    background-color: var(--alt);
    color: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
