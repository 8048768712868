/*===================
=== Start Contact ===
===================*/

.contact {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    position: relative;
}

.contact .title {
    color: #f9faf7;
}

.contact .discount {
    font-size: 1.1em;
    color: #f9faf7;
    font-weight: 400;
    margin: 15px 0;
}

.contact .offer {
    font-size: 0.8em;
    color: #f9faf7;
}

.contact form {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.contact .email,
.contact .phone {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.contact .email input,
.contact .phone input {
    border-radius: 25px;
    width: 270px;
    margin-right: 15px;
    border: 1px solid #c7c4bf;
    font-family: inherit;
    font-size: 1rem;
    height: 3em;
    line-height: 3;
    outline: none;
    padding: 0 0.5rem;
    margin-bottom: 20px;
}

.contact .email button,
.contact .phone button {
    padding: 0 10px;
    border-radius: 25px;
    cursor: pointer;
    height: 3em;
    line-height: 3;
    font-size: 100%;
    border: 0;
    background-color: #007bff;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
}

.contact .description {
    text-align: left;
    font-size: 0.8em;
    color: #f9faf7;
    font-weight: 500;
    margin-top: 20px;
    line-height: 18px;
}

/*=================
=== End Contact ===
=================*/
